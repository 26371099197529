export const useVector = () => {
    const { execute: executeFindCOA, loading } = useApi(
        'api/vector/find-chart-of-account',
        'POST'
    )

    const executeQueryFindCOA = async ({
        query = {},
        filter = {},
        topK = null,
        namespace = null,
    } = {}) => {
        return await executeFindCOA({
            data: {
                query,
                filter,
                topK,
                namespace,
            },
        })
    }

    return {
        executeQueryFindCOA,
        loading,
    }
}
